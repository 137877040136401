import {
  COORDINATES_DEGREES,
  COORDINATES_MINUTES,
  COORDINATES_SECONDS,
} from "../components/common/atoms/coordinatesInput/coordinatesInputConstants";
import { DEFAULT_LANGUAGE, LANGUAGES } from "./constants";

export const no_op = () => undefined;

export const isFunction = (obj) => !!(obj && obj.constructor && obj.call && obj.apply);

export const isString = (string) => typeof string === "string" || string instanceof String;

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatTime = (timeline) => {
  const date = new Date(timeline * 1000);
  const hours = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
  const minutes = date.getMinutes() > 10 ? date.getMinutes() : "0" + date.getMinutes();
  const seconds = date.getSeconds() > 10 ? date.getSeconds() : "0" + date.getSeconds();
  return hours + ":" + minutes + ":" + seconds;
};

export const timestampToDays = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return date.toLocaleDateString("es-ES", options);
};

export const timestampToDaysShort = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const options = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };
  return new Intl.DateTimeFormat('es-ES', options).format(date);
};

export const timestampToDaysShorter = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const options = {
    month: '2-digit',
    day: '2-digit',
  };
  return new Intl.DateTimeFormat('es-ES', options).format(date);
};

export const timestampToDaysNoFormat = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return date.toLocaleDateString("es-ES", options).replaceAll("/", "");
};

export const timestampToDefaultDays = (timestamp) => {
  const fecha = new Date(timestamp * 1000);

  const año = fecha.getFullYear();
  const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
  const dia = ("0" + fecha.getDate()).slice(-2);

  return `${año}-${mes}-${dia}`;
};

export const formatDate = (day) => {
  let date = day?.split("-").map(Number);
  if (date) {
    const newDate = new Date(date[0], date[1] - 1, date[2]);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return newDate.toLocaleDateString("es-ES", options);
  }
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const objectHasValues = (obj) => {
  for (const value of Object.values(obj)) {
    if (!value) return false;
  }
  return true;
};

export const coordinatesToDecimal = (obj) => {
  const { degrees, minutes, seconds } = obj;

  if (!degrees || !minutes || !seconds) return undefined;

  const sign = degrees < 0 ? -1 : 1;

  const formattedDegrees = Math.abs(degrees);
  const formattedMinutes = Math.abs(minutes);
  const formattedSeconds = Math.abs(seconds);

  let decimal = formattedDegrees + formattedMinutes / 60 + formattedSeconds / 3600;

  decimal *= sign;

  return decimal;
};

export const decimalToCoordinates = (decimal) => {
  if (typeof decimal !== "number") return undefined;

  const sign = decimal < 0 ? -1 : 1;

  const absoluteDecimal = Math.abs(decimal);
  const degrees = Math.floor(absoluteDecimal);
  const remainingMinutes = (absoluteDecimal - degrees) * 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes - minutes) * 60);

  return {
    [COORDINATES_DEGREES]: degrees * sign,
    [COORDINATES_MINUTES]: minutes,
    [COORDINATES_SECONDS]: seconds,
  };
};

export const sortArrayByKey = (array, key, invert) => {
  return array.sort((a, b) => {
    const aValue = typeof a[key] === "number" ? a[key] : a[key].toUpperCase();
    const bValue = typeof b[key] === "number" ? b[key] : b[key].toUpperCase();

    let comparison = 0;
    if (aValue < bValue) {
      comparison = -1;
    } else if (aValue > bValue) {
      comparison = 1;
    }

    return invert ? comparison * -1 : comparison;
  });
};

export const filterObject = (obj) => {
  const newObj = {};

  console.log("OBJ >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ", obj);

  for (const key of Object.keys(obj)) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value !== undefined && value !== "") {
        newObj[key] = value;
      }

      if (typeof value === "object") {
        const child = filterObject(value);
        if (Object.keys(child).length) {
          newObj[key] = value;
        } else {
          delete newObj[key];
        }
      }
    }
  }

  return newObj;
};

export const validateEmail = (email) => {
  const regex = /^[\w.ñÑ!#$%&'*+\-/=?^_`{|}~]+@([\wñÑ-]+\.)+[\w]{2,}$/;
  return regex.test(email);
};

export const formatTimestampToDate = (timestamp) => {
  const date = new Date(timestamp * 1000);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getDaysBetweenTimestamps = (timestamp1, timestamp2) => {
  const oneDayMilliseconds = 24 * 60 * 60 * 1000; // Número de milisegundos en un día
  const startDate = timestamp1;
  const endDate = timestamp2; // Convertir el timestamp a una fecha

  const days = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    days.push(new Date(currentDate).getTime() / 1000);
    currentDate.setTime(currentDate.getTime() + oneDayMilliseconds); // Agregar un día
  }

  return days;
};

export const getStartDayTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);

  date.setUTCHours(0, 0, 0, 0);
  date.setHours(date.getHours() - 2);
  return Math.floor(date.getTime() / 1000);
};

export const calcNumberOfParcelsSameRiskLevel = (parcels, parcelsByRisk) => {
  let parcelsGroup = { ...parcelsByRisk };
  parcels?.forEach((parcel) => {
    if (parcel.values_?.plague_risk) {
      parcel.values_?.plague_risk.forEach((plague) => {
        if (parcelsGroup[plague.risk_level] !== undefined && parcelsGroup[plague.risk_level] !== undefined) {
          return (parcelsGroup[plague.risk_level] = parcelsGroup[plague.risk_level] + 1);
        } else {
          return (parcelsGroup.NONE = parcelsGroup.NONE + 1);
        }
      });
    }
  });
  return parcelsGroup;
}

export const getLanguage = () => {
  let locale = localStorage.getItem("locale");
  if (LANGUAGES.includes(locale)) {
    return locale;
  } else {
    return DEFAULT_LANGUAGE;
  }
}
