import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { useKeycloak } from "@react-keycloak/web";
import { useIntl } from "react-intl";
import clsx from "clsx";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import LaunchIcon from "@mui/icons-material/Launch";

import {
  CONTROL_PANEL_URL,
  LOCAL_STORAGE_ITEMS,
  PUBLIC_WEB_AVAILABLE,
  ROLE_AGACAL_SUPER_USER,
  ROLES_PARCEL_ADMIN,
  I18N_CONFIG,
  ROLE_AGACAL_TECHNICAL_USER,
} from "../../../../utils/constants";
import {
  COMMON__ADMIN,
  // COMMON__INTERNATIONALIZATIONS__INTEN,
  COMMON__LOGIN,
  COMMON__LOGOUT,
  HEADER__TITLE,
  MENU__CONTROL_PANEL,
  MENU__MANAGE_CELLARS,
  MENU__MANAGE_PARCELS,
  MENU__MANAGE_USERS,
  MENU__MANAGE_WARNINGS,
  MENU__MAP,
  MENU__MY_PARCELS,
  MENU__REGISTER,
  MENU__WARNIGNS,
  MENU_PHYTOSANITARY,
} from "../../../../translations/constants";
import styles from "./TopMenuComponent.module.css";
import useCustomIntl from "../../../../hook/cutomHook";
import logoHeader from "../../../../images/app_logo.png";
import {
  ROUTE_LANDING,
  ROUTE_MANAGE_CELLARS,
  ROUTE_MANAGE_PARCELS,
  ROUTE_MANAGE_USERS,
  ROUTE_MANAGE_WARNINGS,
  ROUTE_PARCELS,
  ROUTE_PHYTOSANITARY_PRODUCTS,
  ROUTE_REGISTER,
  ROUTE_WARNINGS,
} from "../../../../utils/routes";
import { hasRole, hasRoles } from "../../../../utils/keycloak";
import {commonSlice} from "../../../../reducers/commonState";
import {useDispatch} from "react-redux";

const TopMenuComponent = ({ handleLanguageChanged }) => {
  const language = localStorage.getItem(LOCAL_STORAGE_ITEMS.LOCALE) || navigator.language.split(/[-_]/)[0];
  const dispatch = useDispatch();
  dispatch(commonSlice.actions.changeLanguage(language));
  const { formatMessage } = useCustomIntl();
  const { keycloak, initialized } = useKeycloak();
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [displayAdministrationMenu, setDisplayAdministrationMenu] = useState(false);

  const menu = !!hasRole(ROLE_AGACAL_SUPER_USER)
    ? [
        { text: intl.formatMessage({ id: MENU__WARNIGNS }), url: ROUTE_WARNINGS },
        { text: intl.formatMessage({ id: MENU_PHYTOSANITARY }), url: ROUTE_PHYTOSANITARY_PRODUCTS },
        {
          text: intl.formatMessage({ id: MENU__CONTROL_PANEL }),
          url: CONTROL_PANEL_URL,
          icon: <LaunchIcon sx={{ color: "#002b4a" }} />,
        },
      ]
    : [
        { text: intl.formatMessage({ id: MENU__WARNIGNS }), url: ROUTE_WARNINGS },
        { text: intl.formatMessage({ id: MENU_PHYTOSANITARY }), url: ROUTE_PHYTOSANITARY_PRODUCTS },
      ];

  const administrationSections = hasRole(ROLE_AGACAL_SUPER_USER)
    ? [
        { text: intl.formatMessage({ id: MENU__MANAGE_CELLARS }), url: ROUTE_MANAGE_CELLARS },
        { text: intl.formatMessage({ id: MENU__MANAGE_PARCELS }), url: ROUTE_MANAGE_PARCELS },
        { text: intl.formatMessage({ id: MENU__MANAGE_USERS }), url: ROUTE_MANAGE_USERS },
        { text: intl.formatMessage({ id: MENU__MANAGE_WARNINGS }), url: ROUTE_MANAGE_WARNINGS },
      ]
    : hasRole(ROLE_AGACAL_TECHNICAL_USER) ? [
        { text: intl.formatMessage({ id: MENU__MANAGE_PARCELS }), url: ROUTE_MANAGE_PARCELS },
        { text: intl.formatMessage({ id: MENU__MANAGE_USERS }), url: ROUTE_MANAGE_USERS },
      ]
    : [
      { text: intl.formatMessage({ id: MENU__MANAGE_PARCELS }), url: ROUTE_MANAGE_PARCELS },
    ];

  const changeLanguage = (language) => {
    localStorage.setItem(LOCAL_STORAGE_ITEMS.LOCALE, language);
    handleLanguageChanged(language);
  };

  const handleMainMenu = () => setMainMenuOpen(!mainMenuOpen);

  const handleSession = () => {
    isLoggedIn() ? keycloak.logout({ redirectUri: window.location.origin }) : keycloak.login({ redirectUri: window.location.origin });
  };

  const handleAdministrationOptions = () => {
    setDisplayAdministrationMenu(!displayAdministrationMenu);
  };

  useEffect(() => {
    if (!mainMenuOpen && displayAdministrationMenu) {
      setDisplayAdministrationMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMenuOpen]);

  useEffect(() => {
    setMainMenuOpen(false);
  }, [location]);

  const handleRedirectToHome = () => {
    navigate(ROUTE_LANDING);
  };

  const isLoggedIn = () => {
    return !!initialized && !!keycloak && keycloak.authenticated;
  };

  return (
    <>
      <section className={styles.container}>
        <div className={styles.title}>
          {intl.formatMessage({ id: HEADER__TITLE })}
        </div>
        <div className={styles.logoContainer}>
          <div className={styles.actionButtons}>
            {Object.entries(I18N_CONFIG).map(([lang, text]) => (
              <span key={text} onClick={() => changeLanguage(lang)} className={language === lang ? styles.active : ""}>
                {formatMessage(text)}
              </span>
            ))}
          </div>
          <div onClick={handleRedirectToHome} className={styles.imageContainer}>
            <img height={48} alt="Logo" src={logoHeader} />
          </div>
        </div>
        <MenuIcon className={styles.menuButton} style={{ width: '40px', height: '40px' }} onClick={handleMainMenu} />
      </section>
      {mainMenuOpen && (
        <>
          <nav className={styles.menu}>
            <div className={styles.sections}>
            <Close style={{color: 'white'}} onClick={handleMainMenu} />
              <div className={styles.actionButtonsMobile}>
                {Object.entries(I18N_CONFIG).map(([lang, text]) => (
                  <span
                    key={lang}
                    onClick={() => changeLanguage(lang)}
                    className={language === lang ? styles.active : ""}
                  >
                    {formatMessage(text)}
                  </span>
                ))}
              </div>
              <NavLink className={clsx(styles.menuItem, styles.iconMenuItem)} to={ROUTE_PARCELS}>
                {intl.formatMessage({ id: isLoggedIn() ? MENU__MY_PARCELS : MENU__MAP })}
              </NavLink>
              {isLoggedIn() &&
                menu.map((item) => (
                  <NavLink key={item.url} className={clsx(styles.menuItem, styles.iconMenuItem)} to={item.url}>
                    {item.text} {item.icon}
                  </NavLink>
                ))}
              {!!hasRoles(ROLES_PARCEL_ADMIN) && (
                <span onClick={handleAdministrationOptions} className={clsx(styles.menuItem, styles.iconMenuItem)}>
                  {formatMessage(COMMON__ADMIN)}
                  <ExpandMoreRoundedIcon
                    className={displayAdministrationMenu ? styles["expandedIcon-on"] : styles["expandedIcon-off"]}
                  />
                </span>
              )}
              {displayAdministrationMenu && (
                <div className={styles.administrationMenu}>
                  {administrationSections.map((item) => (
                    <NavLink
                      key={item.url}
                      className={clsx(styles.menuItem, styles.administrationMenuItem)}
                      to={item.url}
                    >
                      {item.text}
                    </NavLink>
                  ))}
                </div>
              )}
              {PUBLIC_WEB_AVAILABLE && !isLoggedIn() ? (
                <NavLink className={clsx(styles.menuItem, styles.iconMenuItem)} to={ROUTE_REGISTER}>
                  {intl.formatMessage({ id: MENU__REGISTER })}
                </NavLink>
              ) : null }
            </div>
            <div className={styles.actions}>
              <span onClick={handleSession} className={styles.menuItemLogout}>
                {formatMessage(isLoggedIn() ? COMMON__LOGOUT : COMMON__LOGIN)}
              </span>
              <div onClick={handleRedirectToHome} className={styles.logoContainerMobile}>
                <img height={38} alt="Logo" src={logoHeader} />
              </div>
            </div>
          </nav>
          <div onClick={handleMainMenu} className={styles.cover} />
        </>
      )}
    </>
  );
};

TopMenuComponent.propTypes = {
  handleLanguageChanged: PropTypes.func.isRequired,
};

export default TopMenuComponent;
