import React, { useEffect, useState } from "react";

import styles from "./Administration.module.css";
import TypographyComponent from "../common/atoms/typography/TypographyComponent";
import { TEXT } from "../common/atoms/typography/typographyConstants";
import { FormattedMessage, useIntl } from "react-intl";
import TablePro from "../common/atoms/tablePro/TablePro";
import ButtonComponent from "../common/atoms/button/ButtonComponent";
import { BUTTON } from "../common/atoms/button/buttonConstants";
import ModalComponent from "../common/molecules/modal/ModalComponent";
import {
  COMMON__ACTION_ACCEPT,
  COMMON__ACTION_DELETE,
  COMMON__ACTION_EDIT,
  COMMON__DELETE_MODAL_TEXT,
  COMMON__DELETE_MODAL_TITLE,
} from "../../translations/constants";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

const AdministrationPageComponent = ({
  title,
  columns,
  rows,
  rowsToShow,
  totalRows,
  buttonText,
  modalComponent,
  displayCreateModal,
  handleOpenCreateModal,
  handleEdit,
  handleDelete,
  createTitle,
  editTitle,
  deleteTitle,
  previousPage,
  nextPage,
  handleChangePage,
  handleOrderParameters,
}) => {
  const intl = useIntl();

  const [displayDeleteWarningModal, setDisplayDeleteWarningModal] = useState(false);
  const [rowData, setRowData] = useState("");
  const [order, setOrder] = useState({});

  const handleOrder = (orderValues) => {
    setOrder(orderValues);
  };

  useEffect(() => {
    handleOrderParameters(order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleDeleteModal = (row) => {
    setRowData(row);
    setDisplayDeleteWarningModal((value) => !value);
  };

  const actionButtons = (row) => {
    return (
      <>
        <span className={styles.actionButtons}>
          <ButtonComponent
            className={styles.adminTableButton}
            icon={<DeleteForeverIcon />}
            hiddenIcon={true}
            text={intl.formatMessage({ id: COMMON__ACTION_DELETE })}
            variant={BUTTON.VARIANT_PRIMARY}
            onClick={() => handleDeleteModal(row)}
            hiddenTextMobileVersion={true}
          />
          {!!handleEdit && (
            <ButtonComponent
            className={styles.adminTableButton}
            icon={<EditIcon />}
            hiddenIcon={true}
            text={intl.formatMessage({ id: COMMON__ACTION_EDIT })}
            variant={BUTTON.VARIANT_PRIMARY}
            onClick={() => {
              handleEdit(row);
              handleTitleForm(editTitle);
            }}
            hiddenTextMobileVersion={true}
          />
          )}
        </span>
      </>
    );
  };

  const [titleForm, setTitleForm] = useState("");
  const handleTitleForm = (value) => {
    setTitleForm(value);
  };

  return (
    <div className={styles.pageContainer}>
      <div>
        <TypographyComponent variant={TEXT.VARIANT_H1} text={<FormattedMessage id={title} />} />
      </div>
      <article>
        <TablePro
          head={columns}
          rows={rows}
          actionButtons={actionButtons}
          displayableTooltip={true}
          numberItemsPage={rowsToShow}
          numberItemsTotal={totalRows}
          previousPage={previousPage}
          nextPage={nextPage}
          handleChangePage={handleChangePage}
          handleOrder={handleOrder}
        />
      </article>
      <div className={styles.buttonContainer}>
        <ButtonComponent
          text={intl.formatMessage({ id: buttonText })}
          variant={BUTTON.VARIANT_PRIMARY}
          onClick={() => {
            handleOpenCreateModal();
            handleTitleForm(createTitle);
          }}
        />
      </div>
      {displayCreateModal && (
        <ModalComponent
          body={modalComponent}
          handleClose={handleOpenCreateModal}
          title={intl.formatMessage({ id: titleForm })}
        />
      )}
      {displayDeleteWarningModal && (
        <ModalComponent
          body={
            <div className={styles.modalContent}>
              <TypographyComponent
                variant={TEXT.VARIANT_P}
                text={`${intl.formatMessage(
                  { id: COMMON__DELETE_MODAL_TEXT },
                  { name: rowData.name || rowData.username },
                )}`}
              />
            </div>
          }
          handleClose={handleDeleteModal}
          title={`${intl.formatMessage(
            { id: COMMON__DELETE_MODAL_TITLE },
            { item: intl.formatMessage({ id: deleteTitle }).toLowerCase() },
          )}`}
          buttonAcceptText={intl.formatMessage({ id: COMMON__ACTION_ACCEPT })}
          handleSave={() => {
            handleDelete(rowData);
            setDisplayDeleteWarningModal((value) => !value);
          }}
        />
      )}
    </div>
  );
};

export default AdministrationPageComponent;
