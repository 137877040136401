import React, { useEffect, useState } from "react";

import Select from "../../../../../../common/atoms/select/SelectComponent";
import style from "./filterPlague.module.css";
import {
  PARCEL__PLAGUE_FILTER,
  PARCEL_SELECT__PLAGUE_FILTER,
  PARCEL_TITLE__BUTTON__ADD_TREATMENT,
} from "../../../../../../../translations/constants";
import { SELECT_FIELD_LABEL, SELECT_FIELD_VALUE } from "../parcelTitleComponent/parcelTitleConstants";
import TagComponent from "../../../../../../common/atoms/tag/TagComponent";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
import useCustomIntl from "../../../../../../../hook/cutomHook";

const FilterPlagueComponent = ({ filterPlagues, plagues, handleFilterPlagues }) => {
  const { formatMessage } = useCustomIntl();
  const [plaguesToSelect, setPlaguesToSelect] = useState(plagues);

  useEffect(() => {
    filterPlaguesSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plagues, filterPlagues]);

  const filterPlaguesSelected = () => {
    const filteredPlaguesSelected = plagues.filter((plague2) => {
      return !filterPlagues.find((plague1) => plague1.id === plague2.id);
    });

    setPlaguesToSelect(filteredPlaguesSelected);
  };

  const removePlagueFilter = (plagueId) => {
    const newFilters = filterPlagues.filter((plague) => {
      return plague.id !== plagueId;
    });

    handleFilterPlagues(newFilters);
  };

  const addPlagueFilter = (event) => {
    const id = parseInt(event.target.value);
    const exist = !!filterPlagues.find((plague) => plague.id === id);
    if (!exist) {
      const newFilters = [...filterPlagues];
      newFilters.push(plagues.find((plague) => plague.id === id));
      handleFilterPlagues(newFilters);
    }
  };

  return (
    <section className={style.container}>
      <TypographyComponent variant={TEXT.VARIANT_H3} text={formatMessage(PARCEL__PLAGUE_FILTER)} />
      <div className={style.filterPlagueContainer}>
        {/*<div className={style.switchContainer}>
          <SwitchComponent />
          <TypographyComponent text="ocultar plagas de riesgo bajo" />
        </div>*/}
        <Select
          data={plaguesToSelect}
          fieldLabel={SELECT_FIELD_LABEL}
          fieldLValue={SELECT_FIELD_VALUE}
          handleOnchange={addPlagueFilter}
          labelOption={formatMessage(PARCEL_SELECT__PLAGUE_FILTER)}
          initialOption={true}
          initialValue={""}
          initialText={formatMessage(PARCEL_SELECT__PLAGUE_FILTER)}
          variant={"secondary"}
        >
          {formatMessage(PARCEL_TITLE__BUTTON__ADD_TREATMENT)}
        </Select>
        {filterPlagues.map((plague) => (
          <TagComponent key={plague?.name} text={plague.name} onClick={() => removePlagueFilter(plague.id)} />
        ))}
      </div>
    </section>
  );
};

export default FilterPlagueComponent;
