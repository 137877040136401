import { object, string, number } from "yup";
import {
  CREATE_WARNING_FORM_INPUT__NAME,
  CREATE_WARNING_FORM_INPUT__DESCRIPTION,
  WARNINGS__RISK_LEVEL_NONE,
  WARNINGS__RISK_LEVEL_LOW,
  WARNINGS__RISK_LEVEL_MEDIUM,
  WARNINGS__RISK_LEVEL_HIGH,
} from "../../../translations/constants";
import { HIGH, LOW, MEDIUM, NONE } from "../../pages/parcels/components/maps/mapConstants";

export const WARNING_SCHEMA = object({
  plot_id: number(),
  name: string().required(),
  description: string().required(),
  level: number(),
});

export const WARNING_UPDATE_SCHEMA = object({
  name: string().required(),
  description: string().required(),
});

export const WARNING_TRANSLATION_SCHEMA = object({
  name: string().required(),
  description: string().required(),
  language_code: string().required(),
});

export const WARNING_ERROR_FIELD = {
  name: CREATE_WARNING_FORM_INPUT__NAME,
  description: CREATE_WARNING_FORM_INPUT__DESCRIPTION,
};

export const WARNING_RISK_LEVEL_0 = { value: 0, label: WARNINGS__RISK_LEVEL_NONE, color: NONE };
export const WARNING_RISK_LEVEL_1 = { value: 1, label: WARNINGS__RISK_LEVEL_LOW, color: LOW };
export const WARNING_RISK_LEVEL_2 = { value: 2, label: WARNINGS__RISK_LEVEL_MEDIUM, color: MEDIUM };
export const WARNING_RISK_LEVEL_3 = { value: 3, label: WARNINGS__RISK_LEVEL_HIGH, color: HIGH };
export const WARNING_LEVELS = [WARNING_RISK_LEVEL_0, WARNING_RISK_LEVEL_1, WARNING_RISK_LEVEL_2, WARNING_RISK_LEVEL_3];

export const SELECT_FIELD_WARNING_LEVELS_VALUE = "value";
export const SELECT_FIELD_WARNING_LEVELS_LABEL = "label";

export const SELECT_FIELD__PLOT_NAME = "name";
export const SELECT_FIELD__PLOT_ID = "id";
export const SELECT_FIELD__LEVEL_NAME = "level";

export const ADMIN_TABLE_ROWS_WARNINGS = 6;
