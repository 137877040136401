import { HIGH, LOW, MEDIUM, NONE } from "../components/pages/parcels/components/maps/mapConstants";
import { riskLevel0, riskLevel1, riskLevel2 } from "../images/icons/icons";
import INTERMITENT_SNOW_IMG from "../images/iconos_normalized_name/INTERMITENT_SNOW.jpg";
import HIGH_CLOUDS_IMG from "../images/iconos_normalized_name/HIGH_CLOUDS.jpg";
import PARTLY_CLOUDY_IMG from "../images/iconos_normalized_name/PARTLY_CLOUDY.jpg";
import OVERCAST_IMG from "../images/iconos_normalized_name/OVERCAST.jpg";
import CLOUDY_IMG from "../images/iconos_normalized_name/CLOUDY.jpg";
import FOG_IMG from "../images/iconos_normalized_name/FOG.jpg";
import SHOWERS_IMG from "../images/iconos_normalized_name/SHOWERS.jpg";
import OVERCAST_AND_SHOWERS_IMG from "../images/iconos_normalized_name/OVERCAST_AND_SHOWERS.jpg";
import DRIZZLE_IMG from "../images/iconos_normalized_name/DRIZZLE.jpg";
import RAIN_IMG from "../images/iconos_normalized_name/RAIN.jpg";
import SNOW_IMG from "../images/iconos_normalized_name/SNOW.jpg";
import STORMS_IMG from "../images/iconos_normalized_name/STORMS.jpg";
import MIST_IMG from "../images/iconos_normalized_name/MIST.jpg";
import FOG_BANK_IMG from "../images/iconos_normalized_name/FOG_BANK.jpg";
import MID_CLOUDS_IMG from "../images/iconos_normalized_name/MID_CLOUDS.jpg";
import WEAK_RAIN_IMG from "../images/iconos_normalized_name/WEAK_RAIN.jpg";
import WEAK_SHOWERS_IMG from "../images/iconos_normalized_name/WEAK_SHOWERS.jpg";
import STORM_THEN_CLOUDY_IMG from "../images/iconos_normalized_name/STORM_THEN_CLOUDY.jpg";
import RAIN_HAIL_IMG from "../images/iconos_normalized_name/RAIN_HAIL.jpg";
import MELTED_SNOW_IMG from "../images/iconos_normalized_name/MELTED_SNOW.jpg";
import SUNNY_IMG from "../images/iconos_normalized_name/SUNNY.jpg";
import {
  INTERMITENT_SNOW,
  HIGH_CLOUDS,
  PARTLY_CLOUDY,
  OVERCAST,
  CLOUDY,
  FOG,
  SHOWERS,
  OVERCAST_AND_SHOWERS,
  DRIZZLE,
  RAIN,
  SNOW,
  STORMS,
  MIST,
  FOG_BANK,
  MID_CLOUDS,
  WEAK_RAIN,
  WEAK_SHOWERS,
  STORM_THEN_CLOUDY,
  RAIN_HAIL,
  MELTED_SNOW,
  SUNNY,
  COMMON__INTERNATIONALIZATIONS__INTES,
  COMMON__INTERNATIONALIZATIONS__INTGL,
} from "../translations/constants";

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const PUBLIC_WEB_AVAILABLE = !!process.env.REACT_APP_PUBLIC_WEB_AVAILABLE
  ? JSON.parse(process.env.REACT_APP_PUBLIC_WEB_AVAILABLE)
  : false;

export const URL_DNS_CUSTOM = process.env.REACT_APP_URL_DNS_CUSTOM

/**
 * KEYCLOAK
 */
export const getApiKCUrl = () => {
  const currentOrigin = window.location.origin;
  return currentOrigin === URL_DNS_CUSTOM ? KEYCLOAK_URL_CUSTOM : KEYCLOAK_URL_ORIGINAL;
};

export const KEYCLOAK_URL_ORIGINAL = process.env.REACT_APP_KC_URL;
export const KEYCLOAK_URL_CUSTOM = process.env.REACT_APP_KC_URL_CUSTOM;
export const KEYCLOAK_URL = getApiKCUrl();
export const KEYCLOAK_REALM = process.env.REACT_APP_KC_REALM;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KC_CLIENT_ID;

/**
 * ROLES
 */
export const ROLE_AGACAL_SUPER_USER = "ADMIN_USER";
export const ROLE_AGACAL_TECHNICAL_USER = "TECH_USER";
export const ROLE_AGACAL_BASIC_USER = "BASIC_USER";

export const ROLES_SUPER = [ROLE_AGACAL_SUPER_USER];
export const ROLES_GENERAL_ADMIN = [ROLE_AGACAL_SUPER_USER, ROLE_AGACAL_TECHNICAL_USER];
export const ROLES_PARCEL_ADMIN = [ROLE_AGACAL_SUPER_USER, ROLE_AGACAL_TECHNICAL_USER, ROLE_AGACAL_BASIC_USER];
export const ROLES_ALL = [ROLE_AGACAL_BASIC_USER, ROLE_AGACAL_SUPER_USER, ROLE_AGACAL_TECHNICAL_USER];
/**
 * NOTIFICATION SEVERITY
 */
export const SEVERITY_SUCCESS = "success";
export const SEVERITY_INFO = "info";
export const SEVERITY_WARNING = "warning";
export const SEVERITY_ERROR = "error";

/**
 * I18N
 */
export const LANGUAGES = ["es", /*"en",*/ "gl"];
export const DEFAULT_LANGUAGE = "es";
export const I18N_CONFIG = {
  es: COMMON__INTERNATIONALIZATIONS__INTES,
  // en: COMMON__INTERNATIONALIZATIONS__INTEN,
  gl: COMMON__INTERNATIONALIZATIONS__INTGL,
};

export const LOCAL_STORAGE_ITEMS = {
  LOCALE: "locale",
};

/**
 * HTTP CODES
 */
export const HTTP_CODE_401 = 401;
export const HTTP_CODE_409 = 409;

/**
 * BACKEND API
 */
export const getApiBaseUrl = () => {
  const currentOrigin = window.location.origin;
  return currentOrigin === URL_DNS_CUSTOM ? AGACAL_API_BASE_URL_CUSTOM : AGACAL_API_BASE_URL_ORIGINAL;
};

export const DEFAULT_HTTP_TIMEOUT = 15000;
export const AGACAL_API_BASE_URL_ORIGINAL = process.env.REACT_APP_AGACAL_API_BASE_URL;
export const AGACAL_API_BASE_URL_CUSTOM = process.env.REACT_APP_AGACAL_API_BASE_URL_CUSTOM;
export const AGACAL_API_BASE_URL = getApiBaseUrl()
export const AGACAL_API_WEATHER_GRAPH = AGACAL_API_BASE_URL + "plots/:plot_id/meteo_graph";
export const AGACAL_API_WEATHER_GRAPH_LAST_UPDATE = AGACAL_API_BASE_URL + "plots/:plot_id/last_updated";

export const AGACAL_API_FEN_STATES = AGACAL_API_BASE_URL + "fen_states/";

export const AGACAL_API_PLOT_FEN_STATES = AGACAL_API_BASE_URL + "plots/:plot_id/fen_states";
export const AGACAL_API_PLOT_FEN_STATE = AGACAL_API_BASE_URL + "plots/:plot_id/fen_state";

export const AGACAL_API_METEO_SUMMARY = AGACAL_API_BASE_URL + "plots/:plot_id/meteo_summary";

export const AGACAL_API_PLAGUE_RISKS = AGACAL_API_BASE_URL + "plots/:plot_id/plague_risks";

export const AGACAL_API_PLOTS = AGACAL_API_BASE_URL + "plots/";
export const AGACAL_API_PLOT = AGACAL_API_BASE_URL + "plots/:plot_id";
export const AGACAL_API_PLAGUES = AGACAL_API_BASE_URL + "plagues/";
export const AGACAL_API_PLOTS_BY_PLAGUE = AGACAL_API_BASE_URL + "plots/";
export const AGACAL_API_CELLARS = AGACAL_API_BASE_URL + "cellars/";
export const AGACAL_API_EDIT_CELLARS = AGACAL_API_BASE_URL + "cellars/:cellar_id";

export const AGACAL_API_TREATMENTS = AGACAL_API_BASE_URL + "plots/:plot_id/treatments";
export const AGACAL_API_TREATMENTS_CREATE = AGACAL_API_BASE_URL + "treatments/";
export const AGACAL_API_TREATMENTS_DELETE = AGACAL_API_BASE_URL + "treatments/:treatment_id";
export const AGACAL_API_ZONES = AGACAL_API_BASE_URL + "zones/";
export const AGACAL_API_VARIETIES_GRAPES = AGACAL_API_BASE_URL + "varieties/";
export const AGACAL_API_GRAPES = AGACAL_API_BASE_URL + "grapes/";

export const AGACAL_API_PROVINCES = AGACAL_API_BASE_URL + "provinces/";
export const AGACAL_API_COUNCILS = AGACAL_API_BASE_URL + "councils/";
export const AGACAL_API_STATIONS = AGACAL_API_BASE_URL + "meteo-stations/";

export const AGACAL_API_WARNINGS = AGACAL_API_BASE_URL + "warnings/";
export const AGACAL_API_WARNING = AGACAL_API_BASE_URL + "warnings/:warning_id";
export const AGACAL_API_WARNING_TRANSLATIONS = AGACAL_API_BASE_URL + "warnings/:warning_id/translation";

export const AGACAL_API_PHYTOSANITARY_PRODUCTS = AGACAL_API_BASE_URL + "products/vademecum";

export const AGACAL_API_USERS = AGACAL_API_BASE_URL + "users/";
export const AGACAL_API_USER = AGACAL_API_BASE_URL + "users/:user_id";
export const AGACAL_API_USER_CHANGE_PASS = AGACAL_API_BASE_URL + "users/change_pass";
export const AGACAL_API_USER_PLOTS = AGACAL_API_BASE_URL + "users/:user_id/plots";
export const AGACAL_API_USER_CELLARS = AGACAL_API_BASE_URL + "users/:user_id/cellars";

export const AGACAL_API_USERS_PUBLIC = AGACAL_API_BASE_URL + "public/users/";
export const AGACAL_API_PLAGUES_PUBLIC = AGACAL_API_BASE_URL + "public/plagues/";
export const AGACAL_API_PLAGUE_RISKS_PUBLIC = AGACAL_API_BASE_URL + "public/map/plague_risks";

/**
 * DATE FORMATS
 */
export const SPANISH_DATE_FORMAT = "DD/MM/YYYY";

export const CONNECTOR_FIRST_URL_PARAMETER = "?";
export const CONNECTOR_URL_PARAMETER = "&";

export const ZONE_PARAMETER = "zone";
export const CELLAR_ID_PARAMETER = "cellar_id";
export const VARIETY_PARAMETER = "variety";
export const NAME_PARAMETER = "name";
export const REG_NUM_PARAMETER = "num_reg"
export const DATE_PARAMETER = "date";
export const PLAGUE_PARAMETER = "plague_ids";

export const NOTHING = null;

export const DATA_WEATHER = {
  [NOTHING]: {
    icon: PARTLY_CLOUDY_IMG,
    alt: NOTHING,
  },
  [HIGH_CLOUDS]: {
    icon: HIGH_CLOUDS_IMG,
    alt: HIGH_CLOUDS,
  },
  [INTERMITENT_SNOW]: {
    icon: INTERMITENT_SNOW_IMG,
    alt: INTERMITENT_SNOW,
  },
  [PARTLY_CLOUDY]: {
    icon: PARTLY_CLOUDY_IMG,
    alt: PARTLY_CLOUDY,
  },
  [OVERCAST]: {
    icon: OVERCAST_IMG,
    alt: OVERCAST,
  },
  [CLOUDY]: {
    icon: CLOUDY_IMG,
    alt: CLOUDY,
  },
  [FOG]: {
    icon: FOG_IMG,
    alt: FOG,
  },
  [SHOWERS]: {
    icon: SHOWERS_IMG,
    alt: SHOWERS,
  },
  [OVERCAST_AND_SHOWERS]: {
    icon: OVERCAST_AND_SHOWERS_IMG,
    alt: OVERCAST_AND_SHOWERS,
  },
  [DRIZZLE]: {
    icon: DRIZZLE_IMG,
    alt: DRIZZLE,
  },
  [RAIN]: {
    icon: RAIN_IMG,
    alt: RAIN,
  },
  [SNOW]: {
    icon: SNOW_IMG,
    alt: SNOW,
  },
  [STORMS]: {
    icon: STORMS_IMG,
    alt: STORMS,
  },
  [MIST]: {
    icon: MIST_IMG,
    alt: MIST,
  },
  [FOG_BANK]: {
    icon: FOG_BANK_IMG,
    alt: FOG_BANK,
  },
  [MID_CLOUDS]: {
    icon: MID_CLOUDS_IMG,
    alt: MID_CLOUDS,
  },
  [WEAK_RAIN]: {
    icon: WEAK_RAIN_IMG,
    alt: WEAK_RAIN,
  },
  [WEAK_SHOWERS]: {
    icon: WEAK_SHOWERS_IMG,
    alt: WEAK_SHOWERS,
  },
  [STORM_THEN_CLOUDY]: {
    icon: STORM_THEN_CLOUDY_IMG,
    alt: STORM_THEN_CLOUDY,
  },
  [RAIN_HAIL]: {
    icon: RAIN_HAIL_IMG,
    alt: RAIN_HAIL,
  },
  [MELTED_SNOW]: {
    icon: MELTED_SNOW_IMG,
    alt: MELTED_SNOW,
  },
  [SUNNY]: {
    icon: SUNNY_IMG,
    alt: SUNNY,
  },
};

export const DRAW_DATA_GRAPHICS = {
  [HIGH]: {
    icon: riskLevel0,
    alt: HIGH,
  },
  [MEDIUM]: {
    icon: riskLevel1,
    alt: MEDIUM,
  },
  [LOW]: {
    icon: riskLevel2,
    alt: LOW,
  },
  [NONE]: {
    icon: riskLevel0,
    alt: NONE,
  },
  DATA_WEATHER,
};

/**
 * BREAKPOINTS
 */
export const BREAKPOINT_XS = "xs";
export const BREAKPOINT_SM = "sm";
export const BREAKPOINT_MD = "md";
export const BREAKPOINT_LG = "lg";
export const BREAKPOINT_XL = "xl";

export const PROTECTION_LEVEL = "protection_level";
export const RISK_LEVEL = "risk_level";

/**
 * OBJECT FIELDS
 */
export const FIELD_ID = "id";

/**
 * VALIDATION ERRORS
 */
export const ERROR_TYPE_OPTIONALITY = "optionality";
export const ERROR_TYPE_REQUIRED = "required";
export const ERROR_TYPE_MIN = "min";
export const ERROR_TYPE_EMAIL = "email";

const REACT_APP_AGACAL_CONTROL_PANEL_URL = process.env.REACT_APP_AGACAL_CONTROL_PANEL_URL;
const REACT_APP_AGACAL_CONTROL_PANEL_URL_CUSTOM = process.env.REACT_APP_AGACAL_CONTROL_PANEL_URL_CUSTOM;
export const getApiPanelUrl = () => {
  const currentOrigin = window.location.origin;
  return currentOrigin === URL_DNS_CUSTOM ? REACT_APP_AGACAL_CONTROL_PANEL_URL_CUSTOM : REACT_APP_AGACAL_CONTROL_PANEL_URL;
};
export const CONTROL_PANEL_URL = getApiPanelUrl();

/**
 * LINKS
 */
export const LINK__CITIZEN_LINK = "https://012.xunta.gal";
export const LINK__ACCESIBILITY = "https://agacal.xunta.gal/gl/accesibilidade";
export const LINK__PORTAL_MAP = "https://agacal.xunta.gal/gl/sitemap";
export const PDF__NAME = "proteccion_datos.pdf";