import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import AddIcon from "@mui/icons-material/Add";
import { yupResolver } from "@hookform/resolvers/yup";

import style from "../../phytosanitaryProducts/PhytosanitaryProductsComponent.module.css";
import { TEXT } from "../../common/atoms/typography/typographyConstants";
import {
  REGISTER__FORM__EMAIL_REQUIRED,
  REGISTER__FORM__EMAIL_VALID,
  REGISTER__FORM__PASSWORD_MAX,
  REGISTER__FORM__PASSWORD_MIN,
  REGISTER__FORM__PASSWORD_REQUIRED,
  REGISTER__FORM__PASSWORD_VALID,
  REGISTER__FORM__PHONE_REQUIRED,
  REGISTER__FORM__PHONE_VALID,
  REGISTER__FORM__USERNAME_REQUIRED,
  REGISTER__FORM__USERNAME_VALID,
  REGISTER__TITLE,
  COMMON__ERROR_MESSAGE_USER_ALREADY_EXISTS,
  REGISTER__DATA_PROTECTION,
} from "../../../translations/constants";
import TypographyComponent from "../../common/atoms/typography/TypographyComponent";
import styles from "./register.module.css";
import { BUTTON } from "../../common/atoms/button/buttonConstants";
import ButtonComponent from "../../common/atoms/button/ButtonComponent";
import TextFieldComponent from "../../common/atoms/form/textfieldComponent/TextField";
import { createUserService } from "../../../services/commonservices";
import { useKeycloak } from "@react-keycloak/web";
import { HTTP_CODE_409, PDF__NAME } from "../../../utils/constants";
import { showMessage } from "../../common/NotificationSnack";
import proteccion_datos from "../../../files/proteccion_datos.pdf";

const RegisterComponent = () => {
  const intl = useIntl();
  const { keycloak } = useKeycloak();

  const schema = yup.object({
    username: yup
      .string()
      .required(intl.formatMessage({ id: REGISTER__FORM__USERNAME_REQUIRED }))
      .matches(/^[A-Za-z0-9_.\-@+áéíóú]*$/, intl.formatMessage({ id: REGISTER__FORM__USERNAME_VALID })),
    email: yup
      .string()
      .email(intl.formatMessage({ id: REGISTER__FORM__EMAIL_VALID }))
      .required(intl.formatMessage({ id: REGISTER__FORM__EMAIL_REQUIRED })),
    password: yup
      .string()
      .min(4, intl.formatMessage({ id: REGISTER__FORM__PASSWORD_MIN }))
      .max(16, intl.formatMessage({ id: REGISTER__FORM__PASSWORD_MAX }))
      .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])[A-Za-z0-9!_@./#&+-]*$/, intl.formatMessage({ id: REGISTER__FORM__PASSWORD_VALID }))
      .required(intl.formatMessage({ id: REGISTER__FORM__PASSWORD_REQUIRED })),
    telephone: yup
      .string()
      .matches(/^[6-9]\d{8}$/, intl.formatMessage({ id: REGISTER__FORM__PHONE_VALID }))
      .required(intl.formatMessage({ id: REGISTER__FORM__PHONE_REQUIRED })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const createCallback = () => {
    keycloak.login({ redirectUri: window.location.origin });
  };

  const createErrorCallback = (error) => {
    if (error.response.status === HTTP_CODE_409) {
      showMessage(intl.formatMessage({ id: COMMON__ERROR_MESSAGE_USER_ALREADY_EXISTS }));
    }
  };

  const onSubmit = (data) => {
    createUserService(data, createCallback, createErrorCallback);
  };

  return (
    <div className={styles.registerPage}>
      <TypographyComponent
        className={style.title}
        variant={TEXT.VARIANT_H2}
        text={<FormattedMessage id={REGISTER__TITLE} />}
      />
      <section className={styles.registerWrapper}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
          <div className={styles.registerForm}>
            <TextFieldComponent
              label={intl.formatMessage({ id: "common__input__user_name" })}
              id="username"
              name="username"
              defaultValue=""
              type="text"
              register={register}
              required
              errorMessage={errors.username?.message}
            />
            <TextFieldComponent
              label={intl.formatMessage({ id: "common__input__email" })}
              id="email"
              name="email"
              defaultValue=""
              type="email"
              register={register}
              required
              errorMessage={errors.email?.message}
            />
            <TextFieldComponent
              label={intl.formatMessage({ id: "common__input__password" })}
              id="password"
              name="password"
              defaultValue=""
              type="password"
              register={register}
              required
              errorMessage={errors.password?.message}
            />
            <TextFieldComponent
              label={intl.formatMessage({ id: "common__input__phone" })}
              id="telephone"
              name="telephone"
              defaultValue=""
              type="tel"
              register={register}
              required
              errorMessage={errors.telephone?.message}
            />
          </div>
          <div className={styles.buttonContainer}>
          {intl.formatMessage({ id: "common__input__email_description" })}
          </div>
          <div className={styles.linkContainer}>
            <div>
              <b><a href={proteccion_datos} download={PDF__NAME} target="_blank"
                rel="noreferrer">
                {intl.formatMessage({ id: REGISTER__DATA_PROTECTION })}</a></b>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <ButtonComponent
              icon={<AddIcon />}
              text={"Crear"}
              variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
              disabled={false}
              type={BUTTON.TYPE_SUBMIT}
            />
          </div>
        </form>
      </section>
    </div>
  );
};

export default RegisterComponent;
