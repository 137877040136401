import React from "react";
import { makeStyles } from "@mui/styles";


import {useIntl} from "react-intl";
import { COMMON__ACCESIBILITY, COMMON__CITIZEN_LINK, COMMON__LEGAL_NOTICIES, COMMON__PORTAL_MAP, FOOTER__COPYRIGHT_TEXT } from "../../translations/constants";
import {useSelector} from "react-redux";
import logoHeader from "../../images/app_logo.png";
import pdfFile_gl from "../../files/aviso_legal_gl.pdf";
import pdfFile_es from "../../files/aviso_legal_es.pdf";
import {LINK__ACCESIBILITY, LINK__CITIZEN_LINK, LINK__PORTAL_MAP} from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  footer: {
    height: 84,
    bottom: 0,
    backgroundColor: "#007bc4",
    color: "white",
    width: "100%",
    position: "relative",
    "& a": {
      color: "white",
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      height: 'auto',
      display: "flex",
      flexDirection: "column",
    },
  },
  logoFooter: {
    padding: theme.spacing(2, 2, 0, 2),
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translate(0, -50%)",
    [theme.breakpoints.down("md")]: {
      position: 'relative',
      left: "50%",
      transform: "translate(-50%, 20%)",
      display: 'flex',
      justifyContent: 'center',
    },
  },
  legalText: {
    textAlign: "right",
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: 'flex-start',
      marginLeft: theme.spacing(3),
      gap: '14px',
    },
  },
  legaNoitices: {
    display: "flex",
    gap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: theme.spacing(3),
      gap: theme.spacing(2),
      "& > b": {
        marginBottom: theme.spacing(1),
      },
    },
  },
  copyright: {
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function FooterComponent() {
  const classes = useStyles();
  const intl = useIntl();
  const commonStateLanguage = useSelector((state) => state.common.language);

  const legalNotices = commonStateLanguage === "es" ? pdfFile_es : pdfFile_gl;

  return (
    <div className={classes.footer}>
      <div className={classes.logoFooter}>
        <div>
          <img height={36} alt="" src={logoHeader} />
        </div>
      </div>
      <div className={classes.legalText}>
        <div className={classes.copyright}>{intl.formatMessage({ id: FOOTER__COPYRIGHT_TEXT })}</div>
        <div className={classes.legaNoitices}>
          <div><b><a href={LINK__CITIZEN_LINK} target="_blank" rel="noreferrer">{intl.formatMessage({ id: COMMON__CITIZEN_LINK })}</a></b></div>
          <div><b><a href={LINK__ACCESIBILITY} target="_blank" rel="noreferrer">{intl.formatMessage({ id: COMMON__ACCESIBILITY })}</a></b></div>
          <div><b>
            <a href={legalNotices} download={`pdfFile_${commonStateLanguage}`} target="_blank"
              rel="noreferrer">
              {intl.formatMessage({ id: COMMON__LEGAL_NOTICIES })}</a>
          </b></div>
          <div><b><a href={LINK__PORTAL_MAP} target="_blank" rel="noreferrer">{intl.formatMessage({ id: COMMON__PORTAL_MAP })}</a></b></div>
        </div>
      </div>
      <div>
        {/*{intl.formatMessage({ id: COMMON__ALL_RIGHTS_RESERVED })}*/}

        {/*    <b>
              <a href={"/"} target="_blank" rel="noreferrer">
                {intl.formatMessage({ id: COMMON__TERMS_OF_USE })}
              </a>
            </b>*/}
      </div>
    </div>
  );
}
